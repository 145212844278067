import type { AdminI } from "@/stores/auth/UserInterface";
import type { SimpleCompanyI } from "@/stores/manuals/ManualsInterface";
import type { CostFilterI, DateFilterI } from "~/stores/search/SearchFormInterface";

export type SortingMode = "asc" | "desc" | "";

export interface SortItemI {
  title: string;
  order: SortingMode;
}

export interface SortingPanelFieldI {
  title: string;
  label: string;
  minWidth?: string;
}

export interface SortingFieldExtendedI extends SortingPanelFieldI {
  sortIndex: number;
}

export interface SortingFormI {
  sorting: SortItemI[];
}

export interface PreFiltrationFormI {
  cost: CostFilterI,
  costApp: CostFilterI,
  datePublic: DateFilterI,
  dateApplication: DateFilterI,
  dateAuction: DateFilterI,
  appStatusIds: number[],
  responsiblePersonIds: number[],
  withoutResponsible: boolean,
  withoutStatus: boolean,
  govRuIds: string[],
  tagIds: number[],
  customerInns: string[],
  lotsSearch: {
    keywords: string[],
    deliveryPlace: string[],
  },
  sorting: SortItemI[],
}

export interface PreFiltrationFormContractI {
  costContract: CostFilterI,
  costContractGuarantee: CostFilterI,
  costPostContractGuarantee: CostFilterI,
  datePlan: DateFilterI,
  dateFact: DateFilterI,
  dateContractSign: DateFilterI,
  contractStatusIds: number[],
  govRuIds: string[],
  tagIds: number[],
  customerInns: string[],
  lotsSearch: {
    keywords: string[],
    deliveryPlace: string[],
  },
  sorting: SortItemI[],
}

export interface PreFiltrationFormServiceI {
  serviceNameId: number,
  serviceStatusIds: number[],
  govRuId: number | null,
  page: number,
  customerCompany: SimpleCompanyI,
  sorting: SortItemI[],
}

export interface PreFiltrationFormClientsI {
  title: string,
  login: string,
  inn: string,
  kpp: string,
  page: number,
  manager: AdminI,
  dateCreate: DateFilterI,
  dateEndTariff: DateFilterI,
  sorting: SortItemI[],
}

export interface PreFiltrationFormPromosI {
  page: number,
  isActual: boolean | undefined,
  sorting: SortItemI[],
}

export function getDefaultSortingForm(): SortingFormI {
  return {
    sorting: [],
  };
}

export function getDefaultPreFiltrationForm(): PreFiltrationFormI {
  return {
    datePublic: {
      dateFrom: null,
      dateTo: null,
    },
    dateApplication: {
      dateFrom: null,
      dateTo: null,
    },
    dateAuction: {
      dateFrom: null,
      dateTo: null,
    },
    cost: {
      costFrom: null,
      costTo: null,
      currency: 1,
      uncertainCost: true,
    },
    costApp: {
      costFrom: null,
      costTo: null,
      currency: 1,
      uncertainCost: true,
    },
    appStatusIds: [],
    tagIds: [],
    sorting: [],
    withoutStatus: false,
    responsiblePersonIds: [],
    withoutResponsible: false,
    govRuIds: [''],
    customerInns: [''],
    lotsSearch: {
      keywords: [],
      deliveryPlace: [],
    },
  }
}

export function getDefaultPreFiltrationFormApp(): PreFiltrationFormI {
  return {
    ...getDefaultPreFiltrationForm(),
  }
}

export function getDefaultPreFiltrationFormContract(): PreFiltrationFormContractI {
  return {
    datePlan: {
      dateFrom: null,
      dateTo: null,
    },
    dateFact: {
      dateFrom: null,
      dateTo: null,
    },
    dateContractSign: {
      dateFrom: null,
      dateTo: null,
    },
    costContract: {
      costFrom: null,
      costTo: null,
      currency: 1,
      uncertainCost: true,
    },
    costContractGuarantee: {
      costFrom: null,
      costTo: null,
      currency: 1,
      uncertainCost: true,
    },
    costPostContractGuarantee: {
      costFrom: null,
      costTo: null,
      currency: 1,
      uncertainCost: true,
    },
    govRuIds: [''],
    tagIds: [],
    customerInns: [''],
    lotsSearch: {
      keywords: [],
      deliveryPlace: [],
    },
    contractStatusIds: [],
    responsiblePersonIds: [],
    withoutResponsible: false,
    sorting: [],
  }
}

export function getDefaultPreFiltrationFormService(): PreFiltrationFormServiceI {
  return {
    serviceNameId: null,
    serviceStatusIds: [],
    govRuId: null,
    customerCompany: null,
    page: 1,
    sorting: [],
  }
}

export function getDefaultPreFiltrationFormClients(): PreFiltrationFormClientsI {
  return {
    title: '',
    inn: '',
    kpp: '',
    page: 1,
    manager: null,
    login: '',
    dateCreate: {
      dateFrom: null,
      dateTo: null,
    },
    dateEndTariff: {
      dateFrom: null,
      dateTo: null,
    },
    sorting: [],
  }
}

export function getDefaultPreFiltrationFormPromos(): PreFiltrationFormPromosI {
  return {
    page: 1,
    isActual: undefined,
    sorting: [{ title: 'dateStart', order: 'desc' }],
  }
}
