<template lang="pug">
validation-row(
  ref="refTimezone"
  not-empty
  label="Часовой пояс"
  :required="required"
  :disabled="disabled"
  :model-value="modelValue"
  :watch-validation="watchValidation"
  @error="$emit('error', 'timezone')"
  @correct="$emit('correct', 'timezone')"
)
  ui-select(
    clearable
    placeholder="Выберите часовой пояс"
    trackBy="value"
    field="time"
    :disabled="disabled"
    :model-value="modelValue"
    :options="timezones"
    @update:modelValue="$emit('update:modelValue', $event)"
  )
    template(#option="{ item }")
      .timezone
        span.time {{ item.time }}
        span.title {{ item.title }}
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { getTimezonesList } from "@/utils/getters/manuals";

import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import UiSelect from "@/components/ui/select/select/UiSelect.vue";

export default defineComponent({
  name: "TimezoneSelector",
  components: {
    ValidationRow,
    UiSelect,
  },
  props: {
    modelValue: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    watchValidation: {
      type: Boolean,
      default: true,
    },
  },
  setup() {

    const timezones = getTimezonesList();

    const refTimezone = ref();

    function validate() {
      refTimezone.value.validate()
    }

    return {
      refTimezone,
      timezones,
      validate,  // для внешней компоненты
    }
  }
})
</script>

<style scoped lang="scss">
.timezone {
  display: flex;
  flex-flow: column;

  .title {
    font-size: 12px;
    line-height: 16px;
    color: var(--main-placeholder-color);
  }
}
</style>
