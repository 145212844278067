import pick from "lodash/pick";
import { ref, nextTick } from "vue";
import { defineStore } from "pinia";
import { redirectAfterLogin } from "@/router/auth";
import { useApi } from "@/use/api/useApi";
import { getFullName } from "@/utils/formatter/nameFormatter";
import { useRoute } from "vue-router";
import { onLogout, prepareEnabled, redirectAfterReset } from "@/utils/authorization/auth";

import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import { Tabs } from "@/router/tabs";

import type { Ref } from "vue";
import type {
  ManagerI,
  UserCompanyI,
  AuthorizedUserI
} from "@/stores/auth/UserInterface";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

function getDefaultUser(): ManagerI {
  return {
    id: NaN,
    fio: "",
    firstName: "",
    middleName: "",
    surName: "",
    phone: "",
    login: "",
    timezone: null,
    role: null,
  }
}

function getDefaultCompany(): UserCompanyI {
  return {
    id: NaN,
    title: '',
    dateEndTariff: '',
    tariff: null,
    isTrialTariff: false,
    hasAccessToHiddenLots: false,
  }
}

export const awaitUser = ref<any>();

export const me = useLocalStorage<ManagerI>('user', getDefaultUser(), { deep: true, listenToStorageChanges: true });
export const company = useLocalStorage<UserCompanyI>('company', getDefaultCompany(), { deep: true, listenToStorageChanges: true });
export const enabled = useLocalStorage<string[]>('enabled', [], { deep: true, listenToStorageChanges: true });

export const campaign = useLocalStorage<string>('campaign', '', { deep: true, listenToStorageChanges: true });

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useAuthStore = defineStore('authStore', () => {

  // const me = ref<ManagerI>(getDefaultUser())
  // const company = ref<UserCompanyI>(getDefaultCompany())
  // const enabled = ref<string[]>([])

  // const campaign = ref<string>('');

  const route = useRoute();
  const { storage } = useServiceStorage();

  function resetUser() {
    me.value = getDefaultUser()
    company.value = getDefaultCompany()
    enabled.value = []
  }

  function setUserData(user: AuthorizedUserI) {
    company.value = user.company
    enabled.value = user.enabled || []

    me.value = {
      ...pick(user, ['login', 'id', 'firstName', 'middleName', 'surName', 'phone', 'role', 'timezone', 'isActual']),
      fio: getFullName(user),
    }
  }

  function getUsersMe(afterLogin = false) {
    if (!awaitUser.value || afterLogin) {
      awaitUser.value = useApi().security.getMe()
        .then((data) => {
          const innerUser: AuthorizedUserI = {
            ...data,
            enabled: prepareEnabled(data.enabled || []),
          }

          setUserData(innerUser)
          if (afterLogin || route.name === Tabs.Landing) redirectAfterLogin()
        })
        .catch(() => {})
        .finally(() => awaitUser.value = undefined)
    }

    return awaitUser.value;
  }

  function logout(loader: Ref) {
    loader.value = true;

    useApi().security.fetchLogout()
      .then(() => {
        storage.onLogout = true;
        redirectAfterReset();
        setTimeout(onLogout, 1000);
      })
      .catch((error) => {
        if (error?.response?.status !== 401) useNotificationsStore().showError('При выходе из аккаунта произошла ошибка, пожалуйста, повторите попытку через несколько минут')
      })
      .finally(() => loader.value = false);
  }

  function resetStore() {
    resetUser()
  }

  return {
    me,
    campaign,
    company,
    enabled,
    getUsersMe,
    logout,
    resetStore,
  }
}, { persist: true })

export default useAuthStore;
