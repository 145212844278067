import payload_plugin_dHWDzdkIDW from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_0Opyapr3uv from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uME0Wj6xpT from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_E6fTeea8JX from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YoSNU22RDV from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HuZntWe2Du from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_REZi7LDpuG from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WNZPaFdQJM from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_OZpMAW6nKL from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_RAyyor6Fox from "/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescri_502f4d6c743f741fcf20998641b56fd3/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import element_plus_HUzmwi0JYR from "/app/plugins/element-plus.ts";
import routes_JiYzrRJOs9 from "/app/plugins/routes.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_0Opyapr3uv,
  unhead_uME0Wj6xpT,
  router_E6fTeea8JX,
  payload_client_YoSNU22RDV,
  navigation_repaint_client_HuZntWe2Du,
  check_outdated_build_client_REZi7LDpuG,
  chunk_reload_client_WNZPaFdQJM,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_OZpMAW6nKL,
  plugin_RAyyor6Fox,
  element_plus_HUzmwi0JYR,
  routes_JiYzrRJOs9,
  sentry_3AyO8nEfhE
]