export function declineWord(count: number) {
  if ((count >= 5 && count <= 20) || count === 0) return 'результатов';

  const digit = count % 10;
  if (digit === 1) return 'результат';
  if (digit >= 2 && digit <= 4) return 'результата';
  return 'результатов'
}

export function declineTenderWord(count: number) {
  if ((count >= 5 && count <= 20) || count === 0) return 'тендеров';

  const digit = count % 10;
  if (digit === 1) return 'тендер';
  if (digit >= 2 && digit <= 4) return 'тендера';
  return 'тендеров'
}

export function declineTenderWordGenitiveCase(count: number) {
  const digit = count % 10;
  if (digit === 1) return 'тендера';
  return 'тендеров'
}

export function setWordEnd(count: number, word: string) {
  if ((count >= 5 && count <= 20) || count === 0) return word + 'ов';

  const digit = count % 10;
  if (digit === 1) return word;
  if (digit >= 2 && digit <= 4) return word + 'а';
  return word + 'ов';
}

export function getCountResults(count: number, total: number) {
  return `${ count === 1 ? 'Показан' : 'Показано' } ${ count } ${ declineWord(count) } из ${ total }.`
}

export function declineUserWord(count: number) {
  if ((count >= 5 && count <= 20) || count === 0) return 'пользователей';

  const digit = count % 10;
  if (digit === 1) return 'пользователь';
  if (digit >= 2 && digit <= 4) return 'пользователя';
  return 'пользователей'
}

export function declineMonthWord(count: number) {
  if ((count >= 5 && count <= 20) || count === 0) return 'месяцев';

  const digit = count % 10;
  if (digit === 1) return 'месяц';
  if (digit >= 2 && digit <= 4) return 'месяца';
  return 'месяцев'
}
