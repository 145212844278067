import { reactive } from "vue";

const storage = reactive({
  scrollIntoAnalytic: false,
  scrollIntoTariff: false,
  scrollIntoContacts: false,
  openNewSearch: false,
  onLogin: false,
  onLogout: false,
  onReload: false,
  ignoreBeforeFetch: false,
  redirect: '',
});

export function useServiceStorage() {
  return {
    storage,
  }
}
