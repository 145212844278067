import validate from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/pages/runtime/validate.js";
import default_45global from "/app/middleware/default.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  default_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  server: () => import("/app/middleware/server.ts")
}