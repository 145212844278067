import { declineMonthWord } from "~/utils/formatter/wordDeclension";
import { costTariff } from "~/utils/formatter/costFormatter";
import type { PromoI, PromoRuleI } from "~/use/other/usePromo";

export function getTariffCost(cost: number, period: number, discount: number) {
  return period === 1 ? cost : Math.trunc((cost * period * discount)/100)*100;
}

export function getDiscountPrice(baseCost: number, discount: number) {
  return Math.trunc((baseCost * (1 - (discount/100)))/100)*100
}

export function getInvoiceProduct(tariffLabel: string, duration: number) {
  return `Услуги по поиску тендеров. Подключение тарифа ТендерМонитора "${ tariffLabel }" на ${ duration } ${ declineMonthWord(duration) }`
}

export function getTariffRadioOption(title: string, duration: number, cost: number, discountCost: number) {
  const label = `"${ title }" на ${ duration } ${ declineMonthWord(duration) }`
  const costLabel = `${ costTariff().format(cost) }`
  const discountLabel = discountCost ? `${ costTariff().format(discountCost) }` : ''

  return `${ label }, <span style="text-decoration: ${ discountLabel ? 'line-through' : 'none' }; text-decoration-color: #FF2B2B;">${ costLabel }</span> ${ discountLabel } ₽`
  // return `${ label }, ${ costLabel } ₽ ${ discountLabel ? '(стоимость по акции ' + discountLabel + ' ₽)' : '' }`
}
