import intersection from "lodash/intersection";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import useAuthStore from "@/stores/auth/useAuthStore";
import useTemplatesStore from "@/stores/templates/useTemplatesStore";
import useSearchStore from "@/stores/search/useSearchStore";
import useApplicationsStore from "@/stores/apps/useApplicationsStore";
import useContractsStore from "@/stores/contracts/useContractsStore";
import useAnalyticsSuppliersStore from "@/stores/analytics/useAnalyticsSuppliersStore";
import useAnalyticsCustomersStore from "@/stores/analytics/useAnalyticsCustomersStore";
import useAnalyticsIndustryStore from "@/stores/analytics/useAnalyticsIndustryStore";
import useInterfaceSettingsStore from "@/stores/settings/useInterfaceSettingsStore";
import useManualsStore from "@/stores/manuals/useManualsStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";
import { Tabs } from "@/router/tabs";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";
import type { RouteLocationNormalized } from "vue-router";

export interface UserInnerI {
  enabled: string[],
  isExpired: boolean,
  isBlocked: boolean,
  isAdmin: boolean,
}

export const availableForNonAuth = ['', Tabs.Landing, Tabs.LotDetail, Tabs.LotGovDetail];
export const availableForNonAuthPath = ['', '/', '/search/detail'];

// availableForManager - это то что доступно без выбора компании
export const availableForManager = [
  Tabs.LotDetail,
  Tabs.LotGovDetail,
  Tabs.Services,
  Tabs.NotificationHistory,
  Tabs.AdminPages.AdminPage,
  Tabs.ReleaseNotesFront,
  Tabs.ReleaseNotesBack,
  ...Object.values(Tabs.Analytics),
  ...Object.values(Tabs.Training),
];
export const availableForBlocked = [Tabs.PageBlocked, Tabs.PageNotAllowed];
export const availableForExpired = [Tabs.PageExpired, Tabs.PageNotAllowed];

const govRuLinkRegex = /\/\d+\/\d+/gm

function getTariffRoles() {
  const config = useRuntimeConfig().public;
  return [
    config.ENABLE_LOT_BASE,
    config.ENABLE_QUICK_SEARCH,
    config.ENABLE_TEMPLATES,
    config.ENABLE_PRO_TEMPLATES,
    config.ENABLE_HIDDEN_TENDERS,
    config.ENABLE_FILTER_TRASH_TENDERS,
    config.ENABLE_COMPANY_UNION,
    config.ENABLE_SMS_NOTIFICATIONS,
    config.ENABLE_EMAIL_NOTIFICATIONS,
    config.ENABLE_ACCESS_CONTROL,
    config.ENABLE_SEARCH_EXCEL,
    config.ENABLE_COMMENTS,
    config.ENABLE_LOT_MANAGEMENT,
    config.ENABLE_ANALYTICS_EXCEL,
    config.ENABLE_ANALYTICS_INDUSTRY,
    config.ENABLE_ANALYTICS_CUSTOMERS,
    config.ENABLE_ANALYTICS_SUPPLIERS,
  ];
}

export function checkBlocked(enabled: string[]) {
  return enabled?.includes(useRuntimeConfig().public.ROLE_BLOCKED) || enabled?.includes(useRuntimeConfig().public.ROLE_COMPANY_BLOCKED)
}

export function checkExpiredPlan(enabled: string[]) {
  // вернет true если это клиент и у него истек тариф
  return !!enabled.length && !checkIsTmManager(enabled) && !enabled.filter(e => getTariffRoles().includes(e)).length
}

export function prepareEnabled(enabled: string[] = []): string[] {
  return checkBlocked(enabled) ? enabled.filter(e => !getTariffRoles().includes(e)) : enabled;
}

export function checkIsTmManager(enabled: string[]) {
  const config = useRuntimeConfig().public;
  return enabled?.some(r=>
    [
      config.TENMON_ADMIN,
      config.TENMON_SENIOR,
      config.TENMON_MANAGER,
    ].includes(r))
}

export function isAccountBlocked(user: any, enabled: string[]) {
  if (checkIsTmManager(enabled)) return !user.isActual
  else return checkBlocked(enabled)
}

export function checkIsTabAvailable(name: string, enabled: string[]) : boolean {
  return intersection(enabled, getRolesByRouteName(name)).length > 0
}

export function getRolesByRouteName(name: string) : string[] {
  const config = useRuntimeConfig().public;
  switch (name) {
    case Tabs.Search:
      return [
        config.ENABLE_LOT_BASE,
        config.ENABLE_QUICK_SEARCH,
        config.ENABLE_TEMPLATES,
        config.ENABLE_PRO_TEMPLATES,
        config.ENABLE_FILTER_TRASH_TENDERS,
        config.ENABLE_HIDDEN_TENDERS,
      ];
    case Tabs.Applications.Main:
    case Tabs.Contracts.Main:
      return [config.ENABLE_LOT_MANAGEMENT];
    case Tabs.Analytics.Main:
      return [
        config.ENABLE_ANALYTICS_INDUSTRY,
        config.ENABLE_ANALYTICS_SUPPLIERS,
        config.ENABLE_ANALYTICS_CUSTOMERS,
      ];
    case Tabs.Analytics.Industry:
      return [config.ENABLE_ANALYTICS_INDUSTRY];
    case Tabs.Analytics.Suppliers:
      return [config.ENABLE_ANALYTICS_SUPPLIERS];
    case Tabs.Analytics.Customers:
      return [config.ENABLE_ANALYTICS_CUSTOMERS];
    case Tabs.Settings.SystemManagement:
      return [config.ENABLE_LOT_MANAGEMENT];
    case Tabs.Settings.UsersManagement:
    case Tabs.Settings.Tariffs:
      return [config.ROLE_COMPANY_ADMIN];
    case Tabs.Settings.TagsSettings:
      return [
        config.ENABLE_LOT_BASE,
        config.ENABLE_QUICK_SEARCH,
        config.ENABLE_TEMPLATES,
        config.ENABLE_PRO_TEMPLATES,
        config.ENABLE_FILTER_TRASH_TENDERS,
        config.ENABLE_HIDDEN_TENDERS,
        config.ENABLE_LOT_MANAGEMENT,
      ];
    default:
      return [];
  }
}

function resetUserStoreData() {
  useTmManagerStore().resetStore();
  useManualsStore().resetStore();
  useAuthStore().resetStore();
}

export function resetAllStoresData() {
  useTemplatesStore().resetStore()

  useSearchStore().resetSearchStore();
  useApplicationsStore().resetApplicationStore();
  useContractsStore().resetContractsStore();

  useAnalyticsSuppliersStore().resetStore();
  useAnalyticsCustomersStore().resetStore();
  useAnalyticsIndustryStore().resetStore();
}

export function onLogout() {
  useInterfaceSettingsStore().resetStore();

  resetAllStoresData();
  resetUserStoreData();
}

/** show that tmManager has selected company */
function isCompanySelected() {
  const tenmonManagerStore = useTmManagerStore();
  return !!tenmonManagerStore.selectedCompanyInfo;
}

/** show message that user does not have access to requested page */
export function noAccessMessage() {
  useNotificationsStore().showError(
    isCompanySelected() ? 'Воспользуйтесь другой вкладкой для просмотра данных' : 'Выберите клиента', 10000);
}

export function checkAvailableForNonAuth() {
  const router = useRouter();
  return (
    (
      !availableForNonAuth.includes(router.currentRoute.value.name as string || '') ||
      !availableForNonAuthPath.filter(a => a).some(a => location.pathname.includes(a))
    ) && !(govRuLinkRegex.test(location.pathname))
  )
}

/** check that tmManager has access to requested page */
export function managerHasAccess(name: string): boolean {
  if (!isCompanySelected()) {
    // если менеджер сидит под клиентом,
    // то разрешить доступ к услугам и менеджерскому аккаунту, остальное закрыть, пока компания не выбрана
    return availableForManager.some(page => page === name);
  }

  // settings
  if ([
    Tabs.Settings.Notifications,
    Tabs.Settings.Account,
    Tabs.Settings.InterfaceSettings,
    Tabs.Settings.Tariffs,
  ].some(page => page === name))
    return false;

  // default
  return true
}

/** check that user (not tmManager) has access to requested page */
export function userHasAccess(name: string, enabled: string[]): boolean {
  if (blockedPagesForUser(name, enabled)) return false;

  const roles = getRolesByRouteName(name)

  if (!roles.length) return true;
  return checkIsTabAvailable(name, enabled || [])
}

export function redirectInAnalytics(enabled: string[]) {
  if (userHasAccess(Tabs.Analytics.Main, enabled)) return redirectInAnalyticsInner(enabled)
  return {name: Tabs.PageNotAllowed, path: "403"};
}

export function redirectInAnalyticsInner(enabled: string[]) {
  if (userHasAccess(Tabs.Analytics.Industry, enabled)) return {name: Tabs.Analytics.Industry, params: {subTab: 'lots'}};
  if (userHasAccess(Tabs.Analytics.Suppliers, enabled)) return {name: Tabs.Analytics.Suppliers, params: {subTab: 'won'}};
  if (userHasAccess(Tabs.Analytics.Customers, enabled)) return {name: Tabs.Analytics.Customers, params: {subTab: 'completed'}};
  return;
}

export function redirectAfterReset() {
  const router = useRouter();
  const { storage } = useServiceStorage();

  if (!availableForNonAuth.includes(router.currentRoute.value.name as string || '')) {
    storage.ignoreBeforeFetch = true;
    navigateTo({ name: Tabs.Landing })
  }
}

/** check is page available for blocked user (not tmManager) */
export function blockedPagesForUser(name: string, enabled: string[]) {
  switch (name) {
    case Tabs.AdminPages.AdminPage:
    case Tabs.AdminPages.PageTariffChanger:
      return true;
    case Tabs.PageExpired:
      // заблокировать доступ если ты заблокирован или у тебя еще не истек тариф
      return checkBlocked(enabled || []) || !checkExpiredPlan(enabled || []);
    case Tabs.PageBlocked:
      // заблокировать доступ если ты не заблокирован
      return !checkBlocked(enabled || []);
    default:
      return false;
  }
}

export function routeByAccess(user: UserInnerI | null, to: RouteLocationNormalized) {
  // проверяем что юзер активный (не заблокирован и тариф активен)
  if (user?.isBlocked || user?.isExpired) {
    if (user?.isBlocked && availableForBlocked.includes(to.name)) return;
    if (user?.isExpired && availableForExpired.includes(to.name)) return;
    useNotificationsStore().showError('Доступ ограничен');
    return { name: Tabs.PageNotAllowed };
  }

  // если юзер - тм менеджер и у него нет доступа к странице
  if (user?.isAdmin && !managerHasAccess(to.name)) {
    noAccessMessage()
    return { name: Tabs.Services };
  }

  // если юзер - обычный юзер и у него нет доступа к странице
  if (!user?.isAdmin && !userHasAccess(to.name, user?.enabled || [])) return { name: Tabs.PageNotAllowed };
  if (to.name === Tabs.Analytics.Main) return redirectInAnalytics(user?.enabled)

  return
}

export function redirectFromLanding(user: UserInnerI | null, checkRedirect = false) {
  const isPlanExpired = !user?.isBlocked && checkExpiredPlan(user?.enabled);

  const isSearchAvailable = checkIsTabAvailable(Tabs.Search, user?.enabled)
  const isAnalyticsAvailable = checkIsTabAvailable(Tabs.Analytics.Main, user?.enabled)

  if (user?.isBlocked) return { name: Tabs.PageBlocked };
  else if (isPlanExpired) return { name: Tabs.PageExpired };

  if (checkIsTmManager(user?.enabled)) return { name: Tabs.Services };
  else {
    const { storage } = useServiceStorage();
    const redirect = storage.redirect;

    if (checkRedirect && redirect) {
      storage.redirect = '';
      if (!redirect.includes('expired') && !redirect.includes('blocked')) return redirect;
    }

    if (isSearchAvailable) return { name: Tabs.Search };
    else if (isAnalyticsAvailable) return redirectInAnalyticsInner(user?.enabled || []);
    else return { name: Tabs.Services};
  }
}
