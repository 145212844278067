import type {
  CreateGroupI,
  CreateTemplateI,
  LoginI,
  MarkViewedI,
  ResetPasswordI,
  UpdateTemplateI
} from "@/use/api/ApiInterface";
import type { OrderI } from "@/use/tenderService/TenderServiceInterface";
import type { LotDetailInterface } from "~/stores/search/LotItemInterface";
import type { AdminI, AuthorizedUserI, InviteUserI } from "@/stores/auth/UserInterface";

import useAuthStore from "~/stores/auth/useAuthStore";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import { logMessage } from "@/utils/logger/logger";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";
import { checkAvailableForNonAuth, onLogout, redirectAfterReset } from "@/utils/authorization/auth";
import {
  campaignFiltersToQuery,
  companiesFiltersToQuery, invoiceFiltersToQuery,
  notificationFiltersToQuery, promosFiltersToQuery,
  serviceFiltersToQuery,
} from "@/utils/formatter/filterFormatter";
import { nextTick } from "vue";

function sendMessageToSentry(ctx: any) {
  console.log('SENTRY', ctx?.options?.method, ctx?.request, ctx?.options?.body, ctx?.error?.message)
  console.log('UID', useAuthStore()?.me?.id)
  logMessage([ctx?.response?.status, ctx?.request].join(' '));
}

function initApi() {
  const fetchApi = $fetch.create({
    baseURL: (useRuntimeConfig().BACK_URL || '') + '/api/',
    headers: {
      Accept: "application/json",
      ContentType: "application/json",
    },
    onResponseError(ctx) {
      if (ctx.response.status === 401) {
        const { storage } = useServiceStorage();
        if (checkAvailableForNonAuth()) {
          if (location.pathname !== '/') storage.redirect = location.pathname + location.search;
        }
        storage.onLogout = true;
        redirectAfterReset();
        setTimeout(onLogout, 1000);
      } else if (ctx.response.status === 403) {
        const { isAuth } = useUserAccess()
        if (isAuth.value) {
          ctx.error = ctx.response._data;
          console.log(ctx.response.status, ctx)
          sendMessageToSentry(ctx)
          useNotificationsStore().showError('Доступ к запрашиваемому ресурсу запрещен')
          return;
        }
      } else if ([404, 412, 500, 502, 504].includes(ctx.response.status)) {
        ctx.error = ctx.response._data;
        console.log(ctx.response.status, ctx)
        if (ctx.response.status !== 404) sendMessageToSentry(ctx)
        return;
      }
    },
  });

  return {
    lots: {
      byTLot: (id: number) => fetchApi<LotDetailInterface>(`lot-cards/${ id }`),
      byGovRu: (lotNumber: string, govRuId: string) => fetchApi<LotDetailInterface>(`lot-cards/${ lotNumber }/${ govRuId }`),
      getDocsFTS: <T>(id: number, payload: any, signal?: AbortSignal) => fetchApi<T>(`lots/${ id }/fts`, { method: 'POST', body: payload, signal }),
      getSimilarLots: (id: number) => fetchApi(`lots/${ id }/similar`),
      getMultipleLotCards: (id: number) => fetchApi(`tenders/${ id }/lots`),
    },
    apps: {
      postApp: (payload: any) => fetchApi('apps', { method: 'POST', body: payload }),
      patchAppInfo: (id: number, payload: any) => fetchApi(`apps/${ id }`, { method: 'PATCH', body: payload }),
    },
    contracts: {
      postContract: (payload: any) => fetchApi('contracts', { method: 'POST', body: payload }),
      patchContractInfo: (id: number, payload: any) => fetchApi(`contracts/${ id }`, { method: 'PATCH', body: payload }),
      fetchContractDates: <T>(id: number) => fetchApi<T>(`contracts/${ id }/dates`),
      patchContractDateSign: <T>(id: number, payload: any) => fetchApi<T>(`contracts/${ id }/date-sign`, { method: 'PATCH', body: payload }),
    },
    comments: {
      postComment: <T>(payload: any) => fetchApi<T>('comments', { method: 'POST', body: payload }),
      patchComment: <T>(id: number, payload: any) => fetchApi<T>(`comments/${ id }`, { method: 'PATCH', body: payload }),
      deleteComment: (id: number) => fetchApi(`comments/${ id }`, { method: 'DELETE' }),
    },
    request: {
      fetchSearchList: <T>(payload: any, offset: number, signal?: AbortSignal) => fetchApi<T>('search', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
      fetchTemplatesList: <T>(payload: any, offset: number, signal?: AbortSignal) => fetchApi<T>('search/templates', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
      fetchAppsList: <T>(payload: any, offset: number, signal?: AbortSignal) => fetchApi<T>('search/apps', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
      fetchContractsList: <T>(payload: any, offset: number, signal?: AbortSignal) => fetchApi<T>('search/contracts', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
    },
    supplemental: {
      fetchSearchSupplemental: (payload: any, signal: AbortSignal) => fetchApi('search/supplemental', { method: 'POST', body: payload, signal }),
      fetchTemplatesSupplemental: (payload: any, signal: AbortSignal) => fetchApi('search/templates/supplemental', { method: 'POST', body: payload, signal }),
      fetchAppsSupplemental: (payload: any, signal: AbortSignal) => fetchApi('search/apps/supplemental', { method: 'POST', body: payload, signal }),
      fetchContractsSupplemental: (payload: any, signal: AbortSignal) => fetchApi('search/contracts/supplemental', { method: 'POST', body: payload, signal }),
    },
    analytics: {
      fetchValidateFilters: (payload: any) => fetchApi('analytics/industry/criteria', { method: 'POST', body: payload }),
      fetchCompanyExist: (payload: any) => fetchApi('orgCompanies', { method: 'POST', body: payload }),
      fetchFilteredCompanies: (payload: any, signal: AbortSignal) => fetchApi(`/suggestions/api/4_1/rs/suggest/party`, {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Token e9390faf8d6ff07fc90c58e65f663f32165802ea"
        },
        method: 'POST', body: payload, signal,
      }),
      industry: {
        fetchIndustryCommon: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/industry/common', { method: 'POST', body: payload, signal }),
        fetchIndustryMonthlyChart: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/industry/monthly', { method: 'POST', body: payload, signal }),
        fetchIndustryRegionsChart: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/industry/regions', { method: 'POST', body: payload, signal }),

        fetchILotsList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/industry/lots', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
        fetchICustomersList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/industry/customers', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
        fetchISuppliersList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/industry/suppliers', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
      },
      suppliers: {
        fetchSuppliersCommon: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/suppliers/common', { method: 'POST', body: payload, signal }),
        fetchSuppliersMonthlyChart: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/suppliers/monthly', { method: 'POST', body: payload, signal }),
        fetchSuppliersRegionsChart: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/suppliers/regions', { method: 'POST', body: payload, signal }),

        fetchSWonList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/suppliers/won', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
        fetchSParticipatedList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/suppliers/participated', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
        fetchSCustomersList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/suppliers/customers', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
        fetchSCompetitorsList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/suppliers/competitors', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
      },
      customers: {
        fetchCustomersCommon: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/customers/common', { method: 'POST', body: payload, signal }),
        fetchCustomersMonthlyChart: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/customers/monthly', { method: 'POST', body: payload, signal }),
        fetchCustomersRegionsChart: <T>(payload: any, signal: AbortSignal) => fetchApi<T>('analytics/customers/regions', { method: 'POST', body: payload, signal }),

        fetchCActualList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/customers/actual', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
        fetchCCompletedList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/customers/completed', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
        fetchCSuppliersList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/customers/suppliers', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
        fetchCParticipantsList: <T>(payload: any, signal: AbortSignal, offset: number) => fetchApi<T>('analytics/customers/participants', { headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal }),
      },
    },
    filtered: {
      fetchOkpd: () => fetchApi('okpd'),
      fetchFilteredSites: <T>(payload: any, signal?: AbortSignal) => fetchApi<T>('site', { method: 'POST', body: payload, signal }),
      fetchFilteredOkpds: <T>(payload: any, signal?: AbortSignal) => fetchApi<T>('okpd', { method: 'POST', body: payload, signal }),
      fetchFilteredCustomers: <T>(payload: any, signal?: AbortSignal) => fetchApi<T>('customers-search', { method: 'POST', body: payload, signal }),
      fetchFilteredSuppliers: <T>(payload: any, signal?: AbortSignal) => fetchApi<T>('suppliers-search', { method: 'POST', body: payload, signal }),
      fetchFilteredIndustries: <T>(payload: any, signal?: AbortSignal) => fetchApi<T>('industries', { method: 'POST', body: payload, signal }),
    },
    /** получение списков данных для работы системы */
    manuals: {
      fetchRegions: () => fetchApi('regions'),
      fetchIndustries: () => fetchApi('industries'),
      fetchCurrencies: () => fetchApi('currencies'),
      fetchAppStatuses: () => fetchApi('apps-statuses'),
      fetchContractStatuses: () => fetchApi('contracts-statuses'),
      fetchServiceStatuses: () => fetchApi('orders-statuses'),
      fetchMailingSchedule: () => fetchApi('mailing-schedule'),
      fetchObjectTypes: () => fetchApi('object-types'),
      fetchSiteGroups: () => fetchApi('site-groups'),
      fetchLaws: () => fetchApi('laws'),
      fetchRoles: () => fetchApi('roles'),
      fetchAdminsRoles: () => fetchApi('admins-roles'),
      fetchConsultingServices: () => fetchApi('consulting-services'),
      fetchDeliveryTypes: () => fetchApi('delivery-types'),
      fetchNotificationTypes: () => fetchApi('notification-types'),
      fetchTariffsList: () => fetchApi('tariffs'),
      fetchTariffsOptions: () => fetchApi('options'),
    },
    /** работа с файлами - скачивание excel */
    excel: {
      fetchFormCalculation: (id: number) => fetchApi(`calculations?lotId=${ id }`),

      downloadSearchData: (payload: any) => fetchApi('search/download', { method: 'POST', body: payload }),                                            // поиск по фильтрам
      downloadTemplatesData: (payload: any) => fetchApi('search/templates/download', { method: 'POST', body: payload }),                               // поиск по шаблонам
      downloadAppsData: (payload: any) => fetchApi('search/apps/download', { method: 'POST', body: payload }),                                         // заявки
      downloadContractsData: (payload: any) => fetchApi('search/contracts/download', { method: 'POST', body: payload }),                               // контракты

      downloadIndustryLotsData: (payload: any) => fetchApi('analytics/industry/lots/download', { method: 'POST', body: payload }),                     // аналитика по отрасли - закупки
      downloadIndustrySuppliersData: (payload: any) => fetchApi('analytics/industry/suppliers/download', { method: 'POST', body: payload }),           // аналитика по отрасли - поставщики
      downloadIndustryCustomersData: (payload: any) => fetchApi('analytics/industry/customers/download', { method: 'POST', body: payload }),           // аналитика по отрасли - заказчики

      downloadSuppliersWonData: (payload: any) => fetchApi('analytics/suppliers/won/download', { method: 'POST', body: payload }),                     // аналитика по поставщику - победил
      downloadSuppliersParticipatedData: (payload: any) => fetchApi('analytics/suppliers/participated/download', { method: 'POST', body: payload }),   // аналитика по поставщику - участвовал
      downloadSuppliersCustomersData: (payload: any) => fetchApi('analytics/suppliers/customers/download', { method: 'POST', body: payload }),         // аналитика по поставщику - заказчики
      downloadSuppliersCompetitorsData: (payload: any) => fetchApi('analytics/suppliers/competitors/download', { method: 'POST', body: payload }),     // аналитика по поставщику - конкуренты

      downloadCustomersActualData: (payload: any) => fetchApi('analytics/customers/actual/download', { method: 'POST', body: payload }),               // аналитика по заказчику - актуальные
      downloadCustomersCompletedData: (payload: any) => fetchApi('analytics/customers/completed/download', { method: 'POST', body: payload }),         // аналитика по заказчику - завершенные
      downloadCustomersParticipantsData: (payload: any) => fetchApi('analytics/customers/participants/download', { method: 'POST', body: payload }),   // аналитика по заказчику - участники
      downloadCustomersSuppliersData: (payload: any) => fetchApi('analytics/customers/suppliers/download', { method: 'POST', body: payload }),         // аналитика по заказчику - поставщики
    },
    /** работа с шаблонами */
    searchTemplates: {
      fetchTemplates: (query: string) => fetchApi(`templates${ query }`),
      fetchGroups: (query: string) => fetchApi(`templates-groups${ query }`),
      fetchCountUnseen: <T>() => fetchApi<T>(`templates/lots/unseen`),
      fetchTemplateInfo: <T>(id: number, signal: AbortSignal) => fetchApi<T>(`templates/${ id }`, { signal }),
      postTemplate: (payload: CreateTemplateI) => fetchApi('templates', { method: 'POST', body: payload }),
      patchTemplate: (id: number, payload: UpdateTemplateI) => fetchApi(`templates/${id}`, { method: 'PATCH', body: payload }),
      deleteTemplate: (id: number) => fetchApi(`templates/${id}`, { method: 'DELETE' }),
      postGroup: <T>(payload: CreateGroupI) => fetchApi<T>('templates-groups', { method: 'POST', body: payload }),
      patchGroup: <T>(id: number, payload: CreateGroupI) => fetchApi<T>(`templates-groups/${id}`, { method: 'PATCH', body: payload }),
      deleteGroup: (id: number) => fetchApi(`templates-groups/${id}`, { method: 'DELETE' }),
      fetchMarkViewed: (payload: MarkViewedI) => fetchApi('templates/lots/unseen', { method: 'POST', body: payload }),
    },
    /** работа с пользователями */
    users: {
      fetchUsers: <T>(query: string) => fetchApi<T>(`users${ query }`),
      postUser: <T>(payload: InviteUserI) => fetchApi<T>('users', { method: 'POST', body: payload }),
      patchUser: <T>(id: number, payload: any) => fetchApi<T>(`users/${ id }`, { method: 'PATCH', body: payload }),
    },
    /** работа с компаниями */
    companies: {
      postCompany: (payload: any) => fetchApi('companies', { method: 'POST', body: payload }),
      fetchCompanyInfo: (id: number) => fetchApi(`companies/${ id }`),
      patchCompanyInfo: (id: number, payload: any) => fetchApi(`companies/${ id }`, { method: 'PATCH', body: payload }),
    },
    /** список изменений */
    releaseNotes: {
      getBackendNotes: <T>() => fetchApi<T>('versions'),
    },
    /** история уведомлений */
    notificationHistory: {
      fetchNotificationsList: <T>(filters: any) => fetchApi<T>(`notifications${ notificationFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" } }),
    },
    /** акции */
    promo: {
      postPromo: (payload: any) => fetchApi(`promos`, { method: 'POST', body: payload }),
      patchPromo: (id: number, payload: any) => fetchApi(`promos/${ id }`, { method: 'PATCH', body: payload }),
      fetchPromoList: <T>(filters: any) => fetchApi<T>(`promos${ promosFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" } }),
      fetchPromoById: <T>(id: number) => fetchApi<T>(`promos/${id}`),
      fetchActualPromo: <T>() => fetchApi<T>('promos/current'),
      fetchPromoPeriodTypes: <T>() => fetchApi<T>('promo-period-types'),
    },
    /** реклама */
    campaigns: {
      fetchCampaignsList: <T>(filters: any) => fetchApi<T>(`promo-campaigns${ campaignFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" } }),
      postCampaign: (payload: any) => fetchApi(`promo-campaigns`, { method: 'POST', body: payload }),
      patchCampaign: (id: number, payload: any) => fetchApi(`promo-campaigns/${ id }`, { method: 'PATCH', body: payload }),
    },
    /** счета на оплату */
    invoices: {
      getInvoice: <T>(filters: any) => fetchApi<T>(`invoices${ invoiceFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" }}),
      getInvoiceStatuses: <T>() => fetchApi<T>('invoice-statuses'),
      postInvoice: <T>(payload: any) => fetchApi<T>('invoices', { method: 'POST', body: payload }),
      patchInvoice: <T>(id: number, payload: any) => fetchApi<T>(`invoices/${ id }`, { method: 'PATCH', body: payload }),
    },
    /** тендерные услуги */
    orders: {
      fetchOrdersList: <T>(filters: any) => fetchApi<T>(`orders${ serviceFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" } }),
      postOrder: (payload: OrderI) => fetchApi('orders', { method: 'POST', body: payload }),
      postOrderExecution: (payload: any) => fetchApi('order-executions', { method: 'POST', body: payload }),
      patchOrder: (id: number, payload: any) => fetchApi(`orders/${ id }`, { method: 'PATCH', body: payload }),
      fetchUnprocessed: <T>() => fetchApi<T>('orders/unprocessed'),
    },
    /** раздел "настройки" */
    settings: {
      users: {
        fetchNotificationsSettings: <T>(query: string) => fetchApi<T>(`settings/notifications${ query }`),
        patchNotificationsSettings: (payload: any) => fetchApi('settings/notifications', { method: 'POST', body: payload }),
        fetchInterfaceSettings: () => fetchApi('users/settings'),
        patchInterfaceSettings: (payload: any) => fetchApi('users/settings', { method: 'POST', body: payload }),
      },
      company: {
        fetchCompanySettings: <T>(query: string) => fetchApi<T>(`companies/settings${ query }`),
        patchCompanySettings: (payload: any) => fetchApi('companies/settings', { method: 'POST', body: payload }),
      },
    },
    /** работа с тегами */
    tags: {
      fetchTenderTags: <T>(query: string) => fetchApi<T>(`tags${ query }`, { headers: { Accept: "application/ld+json" } }),
      postTenderTag: <T>(payload: any) => fetchApi<T>('tags', { method: 'POST', body: payload }),
      patchTenderTag: <T>(id: number, payload: any) => fetchApi<T>(`tags/${ id }`, { method: 'PATCH', body: payload }),
      deleteTenderTag: (id: number) => fetchApi(`tags/${ id }`, { method: 'DELETE' }),
      restoreTenderTag: (id: number) => fetchApi(`tags/${ id }`, { method: 'PATCH', body: { isDeleted: false } }),
      applyTenderTagToLot: (payload: any) => fetchApi('lot_tags', { method: 'POST', body: payload }),
      removeTenderTagFromLot: (id: number) => fetchApi(`lot_tags/${ id }`, { method: 'DELETE' }),
    },
    /** security */
    security: {
      getMe: () => fetchApi<AuthorizedUserI>('security/users/me'),
      fetchEmailExist: (email: string) => fetchApi(`security/email/check?email=${ email }`),
      resetPassword: (email: string) => fetchApi(`security/users/password/forgot?email=${ email }`),
      patchPassword: (id: number, payload: ResetPasswordI) => fetchApi(`security/users/${ id }`, { method: 'PATCH', body: payload }),
      patchLogin: (id: number, payload: any) => fetchApi(`security/users/${ id }`, { method: 'PATCH', body: payload }),
      fetchQuickLink: (id: number) => fetchApi(`security/users/${ id }/quick-link`),
      fetchLogin: (payload: LoginI) => fetchApi('login', { method: 'POST', body: payload }),
      fetchLogout: () => fetchApi('logout'),
    },
    /** для администрации сайта (сотрудников) */
    admin: {
      postAdmin: <T>(payload: any) => fetchApi<T>('admins', { method: 'POST', body: payload }),
      patchAdmin: <T>(id: number, payload: any) => fetchApi<T>(`admins/${ id }`, { method: 'PATCH', body: payload }),
      fetchAdminsList: () => fetchApi<AdminI[]>('admins'),
      fetchManagersCompanies: (filters: string) => fetchApi(`companies${ companiesFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" } }),
      fetchManagersCompaniesByQuery: (query: string, signal: AbortSignal) => fetchApi(`companies${ query ? `?search=${ query }` : '' }`, { headers: { Accept: "application/ld+json" }, signal }),
      applyManagerToCompany: (payload: any) => fetchApi('companies-managers', { method: 'POST', body: payload }),
      applyCrmNumberToCompany: (companyId: number, payload: any) => fetchApi(`companies/${ companyId }`, { method: 'PATCH', body: payload }),
      removeManagerFromCompany: (id: number) => fetchApi(`companies-managers/${ id }`, { method: 'DELETE' }),
    },
  };
}

const cache: { api: ReturnType<typeof initApi> } = {
  api: null as any,
};

export function useApi() {
  if (!cache.api) cache.api = initApi();
  return cache.api;
}

export function useAbort() {
  const abortController = new AbortController();

  return {
    signal: abortController.signal,
    abort: () => abortController.abort(),
  };
}
