import { ref } from "vue";
import { defineStore } from 'pinia';

import type { Ref } from "vue";
import type { NotificationI } from "@/stores/systemNotifications/NotificationInterface";
import { NotificationTypes } from "@/stores/systemNotifications/NotificationInterface";

function deleteMessage(array: Ref<NotificationI[]>, id: string) {
  const index = array.value.findIndex(e => e.id === id);
  if (index > -1) array.value.splice(index, 1);
}

function pushMessage(array: Ref<NotificationI[]>, message: NotificationI) {
  array.value.unshift(message);

  if (message.closeAfter > 0) {
    setTimeout(() => deleteMessage(array, message.id), message.closeAfter);
  }
}

const useNotificationsStore = defineStore('notifications', () => {

  const alerts = ref<NotificationI[]>([]);
  const notifications = ref<NotificationI[]>([]);

  function pushAlert(alert: NotificationI) {
    pushMessage(alerts, alert)
  }

  function pushNotification(notification: NotificationI) {
    pushMessage(notifications, notification)
  }

  function deleteAlert(id: string) {
    deleteMessage(alerts, id)
  }

  function deleteNotification(id: string) {
    deleteMessage(notifications, id)
  }

  function showSuccess(message?: string | null, closeAfter = 3000) {
    pushNotification({
      id: new Date().toISOString(),
      type: NotificationTypes.Success,
      title: 'Успешно',
      message: message ?? 'Изменения успешно сохранены',
      closeAfter,
    });
  }

  function showError(message?: string | null, closeAfter = 4000) {
    pushNotification({
      id: new Date().toISOString(),
      type: NotificationTypes.Error,
      title: 'Ошибка',
      message: message ?? 'Произошла ошибка. Попробуйте повторить позднее',
      closeAfter,
    });
  }

  function showWarning(message?: string | null, closeAfter = 4000, title = 'Ошибка') {
    pushNotification({
      id: new Date().toISOString(),
      type: NotificationTypes.Warning,
      title: title,
      message: message ?? 'Проверьте, что все обязательные поля заполнены корректно',
      closeAfter,
    });
  }

  function showAlert(label?: string, message?: string | null, closeAfter = 14000) {
    pushAlert({
      id: new Date().toISOString(),
      type: NotificationTypes.Error,
      title: label ?? 'Ошибка',
      message: message ?? 'Произошла ошибка. Попробуйте повторить позднее',
      closeAfter,
    });
  }

  return {
    alerts,
    notifications,
    showAlert,
    showSuccess,
    showError,
    showWarning,
    deleteAlert,
    deleteNotification,
  };
});

export default useNotificationsStore;
