export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Система управления ТендерМонитор - быстрый и удобный поиск, автоматизация участия в торгах по 44 и 223 ФЗ. Анализ конкурентов и контрактов. Бесплатная консультация. 8 800 250-13-40"},{"name":"og:site_name","content":"ТендерМонитор"},{"name":"og:title","content":"ТендерМонитор - Система поиска и управления тендерами"},{"name":"og:description","content":"Автоматизация поиска госзакупок и контроль отделов компании. Поиск тендеров по тендерным площадкам. Закупки с сайта госзакупок, ЭТП и сайтов коммерческих компаний."},{"name":"og:image","content":"https://tenmon.ru/pics/landing/main-pic.png"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"Система поиска и управления тендерами | Free Trial","charset":"utf-8","viewport":"width=device-width, initial-scale=1","htmlAttrs":{"lang":"ru"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 1800000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false