import lodashSet from "lodash/set";
import lodashGet from "lodash/get";

import type { Ref } from "vue";
import type { TenderTagI } from "~/stores/search/LotItemInterface";
import type { BaseObjectI, TagI } from "@/stores/manuals/ManualsInterface";
import type { BaseSearchFormInterface, TabMode } from "@/stores/search/SearchFormInterface";
import type { SortingFormI, SortItemI } from "@/utils/getters/defaultFilters";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import useManualsStore from "~/stores/manuals/useManualsStore";
import { getDefaultLotsListSearchForm, getDefaultSearchForm } from "@/utils/getters/defaultSearchForm";

// поля, которые нужно из объекта перегнать в массив айдишников перед отправкой
const tagLabels = [
  "customerIds",
  "supplierIds",
  "okpdCodes",
  "lotAuctionSitesIds",
  "lotAuctionSitesGroups",
];

// поля, которые нужно почистить, если выбраны все опции
const arrayLabels = [
  "places",
  "industryIds",
  "deliveryPlaceIds",
  "lotLawIds",
  "lotAuctionSitesGroups",             // + withoutLotAuctionSite
  "lotTypeShortIds",
];

// поля, которые нужно проигнорировать при подготовке формы к отправке (те, что содержат дефолтные значения)
const ignoreFields = [
  "needAllFilials",
  "lotStatusIds",
  "wordSearchMode",
  "industrySearchMode",
]

function checkEqualArrays(first: any[], second: any[]) {
  return first.length === second.length && isEqual([...first].sort(), [...second].sort());
}

function setCompanyTags(_s: BaseSearchFormInterface, property: 'supplierIds'|'customerIds', value: any) {
  _s[property] = value.map((c: any) => { return { id: c.id, inn: c.inn, kpp: c.kpp, title: c.shortTitle ? c.shortTitle : c.fullTitle }})
}

function setCost(_s: BaseSearchFormInterface, property: 'cost'|'costApp', value: any) {
  _s[property] = {
    ...value,
    costFrom: value.costFrom === 0 ? null : value.costFrom,
    costTo: value.costTo === 0 ? null : value.costTo,
  }
}

function prepareNotEmptySearchForm(_searchForm: any, defaultSF: any): any {
  const searchForm = cloneDeep(_searchForm);

  for (const [key, value] of Object.entries(defaultSF)) {
    if (!ignoreFields.includes(key) && isEqual(value, searchForm[key])) delete searchForm[key];
  }

  if (!searchForm.hasOwnProperty('lotsSearch') || !searchForm.hasOwnProperty('docsSearch')) delete searchForm["wordSearchMode"];
  if (!searchForm.hasOwnProperty('lotsSearch') || !searchForm.hasOwnProperty('industryIds')) delete searchForm["industrySearchMode"];

  if (searchForm.hasOwnProperty('strictCompliance')) delete searchForm["strictCompliance"];
  if (searchForm.hasOwnProperty('allWords')) delete searchForm["allWords"];
  if (searchForm.hasOwnProperty('byWordCombination')) delete searchForm["byWordCombination"];

  return {
    ...searchForm,
  };
}

/** prepare searchForm for sending to backend */
export function tagsToIdList<T extends BaseSearchFormInterface>(_searchForm: T | undefined, tab: TabMode) {

  if (!_searchForm) return;

  const searchForm = cloneDeep(prepareNotEmptySearchForm(_searchForm, getDefaultSearchForm(tab)));
  const manualsStore = useManualsStore();

  const labels = tagLabels.filter(e => searchForm.hasOwnProperty(e))
  for (const tag of labels) {
    const path = tag.split(".");
    const resolvedValue = lodashGet(searchForm, path)
    const value = resolvedValue || [];

    switch (tag) {
      case 'okpdCodes':
      case 'lotAuctionSitesGroups':
        value.map((tag: any, index: number) => value[index] = tag.code);
        break;
      case 'supplierIds':
      case 'customerIds':
      case 'lotAuctionSitesIds':
        value.map((tag: TagI, index: number) => value[index] = tag.id);
        break;
      default: break;
    }
  }

  const labels2 = arrayLabels.filter(e => searchForm.hasOwnProperty(e))
  for (const tag of labels2) {
    const path = tag.split(".");
    const resolvedValue = lodashGet(searchForm, path)
    const value = resolvedValue || [];

    switch (tag) {
      case 'places':
        if (value.every((d: any) => d?.regionIds?.length === 0) && checkEqualArrays(value.map(e => e.districtId), manualsStore.computedDistrictsIds)) {
          delete searchForm['places']
          searchForm.chosenAllPlaces = true
        }
        break;
      case 'industryIds':
        if (checkEqualArrays(value, manualsStore.computedIndustryIds)) {
          delete searchForm['industryIds']
          searchForm.chosenAllIndustryIds = true
        }
        break;
      case 'lotLawIds':
        if (checkEqualArrays(value, manualsStore.computedLotLawsIds)) {
          delete searchForm['lotLawIds']
          searchForm.chosenAllLawIds = true
        }
        break;
      case 'lotTypeShortIds':
        if (checkEqualArrays(value, manualsStore.computedLotTypeShortIds)) {
          delete searchForm['lotTypeShortIds']
          searchForm.chosenAllTypeShortIds = true
        }
        break;
      case 'lotAuctionSitesGroups':
        if (searchForm.withoutLotAuctionSite && checkEqualArrays(value, manualsStore.computedSiteGroupsIds)) {
          delete searchForm['lotAuctionSitesGroups']
          delete searchForm['withoutLotAuctionSite']
          searchForm.chosenAllAuctionSitesGroups = true
        }
        break;
      default: break;
    }
  }

  return searchForm;
}

/** Функция, которая преобразовывает content, полученный из сохраненного шаблона, в searchForm, привычную для фронтенда */
export function contentToSearchForm(_searchForm: any | undefined, tab: TabMode) {
  if (!_searchForm) return;

  const searchForm = getDefaultSearchForm(tab);
  const manualsStore = useManualsStore();

  for (const [key, value] of Object.entries(_searchForm)) {
    switch (key) {
      case 'supplierIds':
      case 'customerIds':
        setCompanyTags(searchForm, key, value)
        break;
      case 'cost':
      case 'costApp':
        setCost(searchForm, key, value)
        break;
      case 'chosenAllPlaces':
        if (_searchForm['chosenAllPlaces']) searchForm['places'] = manualsStore.computedDistrictsIds.map(e => ({ districtId: e, regionIds: [] }))
        break;
      case 'chosenAllIndustryIds':
        if (_searchForm['chosenAllIndustryIds']) searchForm['industryIds'] = manualsStore.computedIndustryIds;
        break;
      case 'chosenAllLawIds':
        if (_searchForm['chosenAllLawIds']) searchForm['lotLawIds'] = manualsStore.computedLotLawsIds;
        break;
      case 'chosenAllTypeShortIds':
        if (_searchForm['chosenAllTypeShortIds']) searchForm['lotTypeShortIds'] = manualsStore.computedLotTypeShortIds;
        break;
      case 'chosenAllAuctionSitesGroups':
        if (_searchForm['chosenAllAuctionSitesGroups']) {
          searchForm['lotAuctionSitesGroups'] = manualsStore.siteGroups;
          searchForm['withoutLotAuctionSite'] = true;
        }
        break;
      default:
        if (searchForm.hasOwnProperty(key)) lodashSet(searchForm, key, cloneDeep(value))
    }
  }

  return searchForm;
}

export function validateSearchForm<T extends BaseSearchFormInterface>(_searchForm: T | undefined) {
  if (!_searchForm) return;

  if (_searchForm?.lotsSearch?.expertSearch) {
    let openedBrackets = 0

    for (const s of _searchForm.lotsSearch.expertSearch) {
      if (s === '(') openedBrackets++;
      else if (s === ')') openedBrackets--;
    }

    if (openedBrackets !== 0) return 'Проверьте корректность заполнения профессионального поиска по словам - количество открывающихся и закрывающихся скобок не совпадает'
  }

  if (_searchForm?.docsSearch?.exceptionWords?.length > 0) {
    if (_searchForm?.wordSearchMode === 2 && !_searchForm.docsSearch.keywords.length) return 'Заполните ключевые слова поиска по файлам документации или удалите введенные слова исключения. Система не позволяет искать тендеры только по словам исключениям.'
    if (_searchForm?.wordSearchMode === 1 && !(_searchForm.docsSearch.keywords.length || _searchForm.lotsSearch.keywords.length || _searchForm.lotsSearch.expertSearch)) return 'Заполните ключевые слова поиска по названию или по файлам документации, или удалите введенные слова исключения. Система не позволяет искать тендеры только по словам исключениям.'
  }

  return ''
}

export function prepareFastSearchForm<T extends BaseSearchFormInterface>(_searchForm: T | undefined) {
  if (!_searchForm) return;


  // searchFormModel.value.datePublic = { dateFrom: addYears(new Date(), -2), dateTo: new Date() }
  // searchFormModel.value.datePublic = { dateFrom: null, dateTo: null }
}

export function getPreparedSorting(sorting: Array<SortItemI>) {

  if (!sorting || sorting.length === 0) return {};

  const result = {
    sorting: {}
  };

  sorting?.forEach((filter, index) => {
    result.sorting[filter.title + "Order"] = {
      direction: filter.order.toUpperCase(),
      priority: index + 1,
    };
  });

  return result;
}

export function prepareNotEmptyPreFiltrationAndSorting(_preFiltrationForm: any, tempDefaultPreFiltrationForm: any): any {
  const preFiltrationForm = cloneDeep(_preFiltrationForm);

  for (const [key, value] of Object.entries(_preFiltrationForm)) {
    if (isEqual(value, tempDefaultPreFiltrationForm[key]) || (key === 'sorting' && preFiltrationForm[key].length === 0)) {
      // @ts-ignore
      delete preFiltrationForm[key];
    }
  }

  if (preFiltrationForm.hasOwnProperty('responsiblePersonIds')) {
    preFiltrationForm.responsiblePersonIds[0] = preFiltrationForm.responsiblePersonIds[0]?.id
  }

  // if (preFiltrationForm.hasOwnProperty('tagIds')) {
  //   preFiltrationForm.tagIds = preFiltrationForm.tagIds.map(e => e.id)
  // }

  return {
    ...preFiltrationForm,
    ...getPreparedSorting(_preFiltrationForm.sorting)
  };
}

export function prepareNotEmptyPreFiltration(_preFiltrationForm: any, tempDefaultPreFiltrationForm: any): any {
  const preFiltrationForm = cloneDeep(_preFiltrationForm);

  for (const [key, value] of Object.entries(_preFiltrationForm)) {
    if (isEqual(value, tempDefaultPreFiltrationForm[key]) || (key === 'sorting' && preFiltrationForm[key].length === 0)) {
      // @ts-ignore
      delete preFiltrationForm[key];
    }
  }

  return {
    ...preFiltrationForm,
  };
}

// todo check
/** refactor cost filter for fetching data do not contradict search form data */
export function checkCostFilter<T extends BaseSearchFormInterface>(_searchForm: T | undefined, filters: any) {
  const f = cloneDeep(filters)
  const defaultSearchForm = getDefaultLotsListSearchForm();
  if (filters.hasOwnProperty('cost') && !isEqual(_searchForm?.cost, defaultSearchForm.cost)) {
    f.cost = {
      costFrom: f.cost?.costFrom ? f.cost?.costFrom : (_searchForm?.cost?.costFrom || null),
      costTo: f.cost?.costTo ? f.cost?.costTo : (_searchForm?.cost?.costTo || null),
      currency: _searchForm?.cost?.currency || defaultSearchForm.cost!.currency,
      uncertainCost: _searchForm?.cost?.uncertainCost || defaultSearchForm.cost!.uncertainCost,
    }
  }
  if (filters.hasOwnProperty('costApp') && !isEqual(_searchForm?.costApp, defaultSearchForm.costApp)) {
    f.costApp = {
      costFrom: f.costApp?.costFrom ? f.costApp?.costFrom : (_searchForm?.costApp?.costFrom || null),
      costTo: f.costApp?.costTo ? f.costApp?.costTo : (_searchForm?.costApp?.costTo || null),
      currency: _searchForm?.costApp?.currency || defaultSearchForm.costApp!.currency,
      uncertainCost: _searchForm?.costApp?.uncertainCost || defaultSearchForm.costApp!.uncertainCost,
    }
  }
  return f;
}

export function assignSorting(searchForm: any, sorting: SortingFormI) {
  return Object.assign(cloneDeep(searchForm), getPreparedSorting(sorting.sorting));
}

export function setDocsWords(searchForm: Ref) {
  lodashSet(searchForm.value, 'docsSearch.keywords', cloneDeep(lodashGet(searchForm.value, 'lotsSearch.keywords')))
  lodashSet(searchForm.value, 'docsSearch.exceptionWords', cloneDeep(lodashGet(searchForm.value, 'lotsSearch.exceptionWords')))
}

/**
 * для мультиселекта и тег инпутов
 * добавляет выбранный тег в список
 */
export function addTagRef<T extends TagI>(vmodel: Ref<any[]>, data: Array<T>) {
  vmodel.value = (vmodel.value ?? []).concat(data)
}

/**
 * для мультиселекта и тег инпутов
 * удаляет выбранный тег из списка
 */
export function deleteTagRef(vmodel: Ref<any[]>, data: Array<number|string>) {
  vmodel.value = (vmodel.value ?? []).filter((tag: any) => !data.includes(tag.id));
}

/**
 * для мультиселекта и тег инпутов
 * select - показывает выбрать или удалить
 * vmodel - модифицируемое значение
 * data - список объектов, которые нужно добавить/удалить
 */
export function selectAllRef(select: boolean, vmodel: Ref<any[]>, data: any[]) {
  if (select) {
    const ids = vmodel.value?.map(e => e.id) || []
    addTagRef(vmodel, data?.filter((tag: BaseObjectI) => !ids.includes(tag.id)))
  } else {
    deleteTagRef(vmodel, data?.map((tag: BaseObjectI) => tag.id))
  }
}

/**
 * проверка на то, что в фильтрах нет удаленных тегов
 * возвращает отфильтрованное значение если фильтр содержал удаленные значения
 * и false если все ок
 * selectedTags - выбранные теги в панели фильтров
 * list - список актуальных тегов
 */
export function checkTagFiltersOnActuality(selectedTags: number[], list: TenderTagI[]) {
  if (selectedTags.length && !selectedTags.every(t => list.find(e => e.id === t))) {
    return selectedTags.filter(t => !!list.find(e => e.id === t))
  } else return false;
}
