<template>
  <div v-if="mode === 'agreement'" class="agreement">
    {{ title }}, я соглашаюсь на передачу персональных данных согласно
    <a class="link" target="_blank" href="https://nposys.ru/policy/">Политике конфиденциальности</a>
    и
    <a class="link" target="_blank" href="https://nposys.ru/agreement/">Пользовательскому соглашению</a>,
    а также на получение информационных сообщений от компании ООО "НПО "Система".
  </div>
  <div v-if="mode === 'info'" class="agreement">
    Наш менеджер свяжется с вами в ближайшее время для согласования
    стоимости услуги и уточнению дополнительной информации
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

export default defineComponent({
  name: "Agreement",
  props: {
    title: {
      type: String,
      default: 'Отправляя заявку',
    },
    mode: {
      type: String as PropType<'agreement'|'info'>,
      default: 'agreement',
    },
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/links';

.agreement {
  font-size: 11px;
  line-height: 16px;

  .link {
    @include link-mixin;
  }
}
</style>
