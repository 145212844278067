<template lang="pug">
button(:type="buttonType" :disabled="loader || disabled" :class="classView")
  mini-loader.loader(v-if="iconLeft && loader")
  ui-icon(v-else-if="iconLeft" :size="16" :icon="iconLeft")
  slot
  mini-loader.loader(v-if="!iconLeft && loader")
  ui-icon(v-if="iconRight && !loader" :size="16" :icon="iconRight")
</template>

<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent } from "vue";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import MiniLoader from "@/components/ui/loader/MiniLoader.vue";

export default defineComponent({
  name: "UiButton",
  components: {
    UiIcon,
    MiniLoader,
  },
  props: {
    type: {
      type: String as PropType<"primary" | "secondary" | "tertiary" | "link">,
      default: "primary",
    },
    size: {
      type: String as PropType<"small" | "medium" | "large">,
      default: "medium",
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    iconLeft: String,
    iconRight: String,
    buttonType: {
      type: String as PropType<"button" | "submit">,
      default: 'button',
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {

    const classView = computed(() => {
      const result = ["ui-button"];
      result.push(`ui-button-${props.type}`);
      result.push(`ui-button-${props.size}`);

      if (props.disabled) result.push("_disabled");
      return result;
    });

    return {
      classView,
    };
  },
});
</script>

<style scoped lang="scss">
.ui-button {
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  background: none;
  border: 1px solid transparent;
  align-items: center;
  gap: 8px;

  .loader {
    width: 16px;
    height: 16px;
  }

  &._disabled {
    cursor: default;

    color: var(--button__disabled-text);
    border-color: var(--button__disabled-background);
    background-color: var(--button__disabled-background);
  }

  &:active:enabled {
    transform: scale(0.99);
  }
}

.ui-button-primary {
  color: var(--main-color-white);
  background-color: var(--main-color-blue);

  &:hover:not(._disabled) {
    background-color: var(--button__primary_hover);
  }
}

.ui-button-secondary {
  border: 1px solid #dcdfe5;
  background-color: var(--main-color-white);
  color: #606266;

  &:hover:not(._disabled) {
    background-color: #ecf5ff;
    border: 1px solid #c6e2ff;
    color: var(--main-color-blue);
  }
}

.ui-button-tertiary {
  color: var(--main-color-blue);
  background-color: #ecf5ff;

  &:hover:not(._disabled) {
    background-color: #dfefff;
  }
}

.ui-button-link {
  color: var(--main-color-blue);

  &:hover:not(._disabled) {
    background-color: #ecf5ff;
  }
}

.ui-button-small {
  font-size: 12px;
  line-height: 16px;
  padding: 4px 12px;
}

.ui-button-medium {
  font-size: 13px;
  line-height: 16px;
  padding: 7px 12px;
}

.ui-button-large {
  font-size: 14px;
  line-height: 20px;
  padding: 9px 12px;
}
</style>
