import { ref, watch } from "vue";
import { useApi } from "@/use/api/useApi";
import { useRouter } from "vue-router";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useTenderService } from "@/use/tenderService/useTenderService";
import { sendMetrica, type YmGoalType } from "~/utils/logger/metrica";
import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import type { ManagerI } from "@/stores/auth/UserInterface";
import type { OrderFormI, OrderI } from "@/use/tenderService/TenderServiceInterface";
import { Tabs } from "@/router/tabs";

function getDefaultForm(user: ManagerI) : OrderFormI {
  return {
    name: user.fio,
    phoneNumber: user.phone,
    timezone: user.timezone ?? null,
    email: user.login,
    comment: "",
  }
}

/** логика заказа услуг */
export function useOrderForm(close: Function|undefined, showNotification = true) {

  const router = useRouter();

  const { me } = useUserAccess();
  const { showSuccess, showError } = useNotificationsStore();

  const form = ref(getDefaultForm(me.value));
  const isSaving = ref(false);
  const showSuccessMessage = ref(false);
  const showErrorMessage = ref(false);

  const {
    lot,
    isOpen,
    needReload,
    selectedServiceId,
    closeTenderService,
  } = useTenderService(close);

  const orderFormRef = ref();
  const service = ref<number>(selectedServiceId.value);

  watch(selectedServiceId, (newValue: any) => {
    service.value = newValue
  })

  watch(me, (newValue: any) => {
    form.value = getDefaultForm(newValue)
  })

  function hideForm() {
    closeTenderService()
    form.value = getDefaultForm(me.value)
    service.value = selectedServiceId.value
  }

  function validate() {
    orderFormRef.value.validate();
  }

  function createOrder(serviceId?: number, systemComment?: string, metric?: YmGoalType) {

    isSaving.value = true;
    showSuccessMessage.value = false;
    showErrorMessage.value = false;

    const order : OrderI = {
      ...form.value,
      user: me.value?.id ? '/api/users/' + me.value.id : null,
      consultingService: '/api/consulting-services/' + (serviceId ? serviceId : service.value),
      lot: lot.value?.lotId ? '/api/lots/' + lot.value.lotId : null,
      systemComment: systemComment ? systemComment : undefined,
    }

    useApi().orders.postOrder(order)
      .then(() => {
        hideForm();
        showSuccessMessage.value = true;
        if (showNotification) showSuccess('Ваше обращение принято в работу. Ожидайте звонка');
        if (metric) sendMetrica(metric)
        if (router.currentRoute.value.name === Tabs.Services) needReload.value = true;
      })
      .catch(() => {
        showErrorMessage.value = true;
        if (showNotification) showError()
      })
      .finally(() => isSaving.value = false)
  }

  return {
    form,
    isSaving,
    lot,
    isOpen,
    needReload,
    service,
    orderFormRef,
    showSuccessMessage,
    showErrorMessage,
    createOrder,
    hideForm,
    validate,
  }
}
