import { Tabs } from "@/router/tabs";
import useAuthStore, { awaitUser } from "@/stores/auth/useAuthStore";
import type { RouteLocationNormalized } from "vue-router";
import {
  availableForBlocked,
  availableForExpired,
  checkExpiredPlan,
  checkIsTabAvailable,
  checkIsTmManager,
  isAccountBlocked,
  managerHasAccess,
  redirectFromLanding,
  redirectInAnalyticsInner,
  routeByAccess,
  userHasAccess,
  type UserInnerI,
} from "@/utils/authorization/auth";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";

function GETUSER() : UserInnerI | null {
  const authStore = useAuthStore()

  const me = authStore.me
  const roles = authStore.enabled || []

  if (!me?.id) return null
  const blocked = isAccountBlocked(me, roles || [])
  return {
    enabled: roles || [],
    isBlocked: blocked,
    isExpired: !blocked && checkExpiredPlan(roles || []),
    isAdmin: checkIsTmManager(roles || [])
  }
}

export function getMainPageNavigateRoute() : { name: string } {
  const user = GETUSER();

  const isSearchAvailable = checkIsTabAvailable(Tabs.Search, user?.enabled || [])
  const isAnalyticsAvailable = checkIsTabAvailable(Tabs.Analytics.Main, user?.enabled || [])

  if (user?.isExpired) return { name: Tabs.PageExpired };
  if (user?.isBlocked) return { name: Tabs.PageBlocked };

  if (isSearchAvailable) return { name: Tabs.Search };
  else if (isAnalyticsAvailable) return redirectInAnalyticsInner(user?.enabled || []);
  else return { name: Tabs.Services};
}

// TODO мне кажется можно вынести и переписать на что-то другое, не используя гетюзер
export function accountHasAccess(name: string) : boolean {
  const user = GETUSER()
  if (user?.isBlocked) return availableForBlocked.includes(name);
  if (user?.isExpired) return availableForExpired.includes(name);
  if (user?.isAdmin) return managerHasAccess(name);
  return userHasAccess(name, user?.enabled || []);
}

export function redirectAfterLogin() {
  const user = GETUSER()

  const router = useRouter();
  if (router.currentRoute.value.name === Tabs.Landing) {
    const redirect = redirectFromLanding(user, true)
    if (redirect) navigateTo(redirect)
  }
}

export async function waitUserData(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  let user = GETUSER()
  console.log('waitUserData', user)
  // если пользователь не был авторизован (в storage нет его данных)
  if (!user) {
    // если страница доступна всем - пропускать
    if (!to.meta.requiresAuth) return;
    else {
      // иначе ждем getUsersMe
      await awaitUser.value;
      user = GETUSER()
    }
  }
  // иначе (если данные есть или нам пришел 200 на getUsersMe)
  if (user) return to.name === Tabs.Landing && (from.name === Tabs.Landing || from.name === undefined) ? redirectFromLanding(user) : routeByAccess(user, to)

  // если совсем ничего по юзеру нет и он хочет внутрь - отправлять на лендинг
  if (to.meta.requiresAuth) {
    const { storage } = useServiceStorage();
    storage.redirect = to.fullPath;
    return { name: Tabs.Landing };
  }

  return;
}
