import { ref } from "vue";
import { useApi } from "@/use/api/useApi";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { getDiscountPrice } from "~/utils/tariffs/priceGenerator";
import type { BaseObjectI } from "~/stores/manuals/ManualsInterface";

export interface PromoRuleI {
  value: string,
  isRubles: boolean,
}

export interface PromoI {
  id: number | null,
  current?: boolean,
  serviceTitle: string,
  landingPromoTitle: string,
  landingPromoAgreement: string,
  promoTitle: string,
  promoDescription: string,
  promoAgreement: string,
  rules: Record<string, PromoRuleI>,
  isNewUsersOnly: boolean,
  dateCreate: string | null,
  dateStart: string | null,
  dateFinish: string | null,
  isActual: boolean,
  periodType: number,    // периодизация акции - ежегодная/ежеквартальная/единичная
}

interface RuleResponseI {
  tariff: BaseObjectI,
  tariffDuration: number,
  value: string,
  isRubles: boolean,
}

interface PromoPeriodI {
  id: number,
  title: number,
  isSupported: boolean,
}

function validateRules(r: RuleResponseI[]) {
  let res : Record<string, PromoRuleI> = {}
  r.forEach(rule => {
    res[`${rule?.tariff?.id}-${rule.tariffDuration}`] = {
      value: rule.value,
      isRubles: rule.isRubles,
    }
  })
  return res;
}

const promo = ref<PromoI>();
const currentPromoList = ref<PromoI[]>([])
const promoPeriodTypes = ref<PromoPeriodI[]>([]);

export function usePromo() {

  const { isAuth, isTrial } = useUserAccess();

  /** функция для валидации респонса акции с бека */
  function validateResponse(response: any) : PromoI {
    return {
      ...response,
      periodType: response?.periodType?.id || 1,
      rules: validateRules(response?.rules || []),
    }
  }

  /** получение актуальной акции для отображения на лендинге и в тарифах */
  function getActualPromoDetails() {
    useApi().promo.fetchActualPromo()
      .then((response: any) => {
        if (response) currentPromoList.value = response.map(e => validateResponse(e))
        if (currentPromoList.value.length) promo.value = currentPromoList.value[0]
      })
      .catch(() => {})
  }

  /** получение списка периодизаций акций */
  function getPromoPeriod() {
    useApi().promo.fetchPromoPeriodTypes()
      .then((response: any) => promoPeriodTypes.value = response)
  }

  /** функция для расчета промо-цены для конкретного тарифа и конкретного периода */
  function getTariffPromoCost(cost: number, tariffId: number, period: number) {
    const priceRule = promo.value?.rules[`${ tariffId }-${ period }`]
    if (!priceRule) return undefined

    const promoPriceInRubles = priceRule?.isRubles ? priceRule?.value : getDiscountPrice(cost, priceRule.value)
    // если промо для всех, возвращаем промо-цену
    if (!promo.value?.isNewUsersOnly) return promoPriceInRubles

    // если промо для новых пользователей, проверяем, можно ли выводить
    else {
      if (!isAuth.value || isTrial.value) return promoPriceInRubles
      else return undefined
    }
  }

  function getDefaultPromoData() : PromoI {
    return {
      id: null,
      serviceTitle: '',
      landingPromoTitle: '',
      landingPromoAgreement: '',
      promoTitle: '',
      promoDescription: '',
      promoAgreement: '',
      rules: {},
      isNewUsersOnly: true,
      dateCreate: '',
      dateStart: '',
      dateFinish: '',
      isActual: false,
      periodType: 1,
    }
  }

  return {
    promo,
    promoPeriodTypes,
    currentPromoList,
    getTariffPromoCost,
    getPromoPeriod,
    validateResponse,
    getActualPromoDetails,
    getDefaultPromoData,
  }
}
