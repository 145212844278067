import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.7_db0@0.2.1_ioredis@5.4.2_magicast@_856b1847220d9dd7bf96e7c4dd7ea511/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/app/router.options";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}